<template>

   <v-row justify="center" no-gutters>
        <v-col class="my-0 py-0">
            <div v-for="(item, j) in detailsItems" :key="j">
                <v-row justify="center" align="center" no-gutters class="my-2">
                    <v-col align-self="start" v-if="item.concepto !== null"><span class="font-weight-bold normalText--text">{{ item.concepto }}</span></v-col>
                    <v-col :class="{'text-right': item.concepto !== null}" v-if="!item.isFile">
                        <v-skeleton-loader v-if="item.valor === null" ref="skeleton" type="text" class="mb-0 mx-auto"></v-skeleton-loader>
                        <span v-else class="normalText--text pre" :data-cy="item.cy">{{ item.valor }}</span>
                    </v-col>
                    <v-col :class="{'text-right': item.concepto !== null}" v-if="item.isFile">
                        <v-skeleton-loader v-if="item.valor === null" ref="skeleton" type="text" class="mb-0 mx-auto"></v-skeleton-loader>
                        <a  v-else target="_blank" :href="item.valor"><v-icon  color="secondary" >mdi-open-in-new</v-icon></a>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </div>
        </v-col>
    </v-row>

</template>

<script>
export default {
    name: 'details-card',
    props: ['detailsItems'],    
    data: () => ({

    }),
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 