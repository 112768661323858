<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center " md="6" lg="6" xl="6">
                        <div class="font-weight-bold body-1">
                            Detalles de Cotización
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="6" lg="6" xl="6" >

                    <details-item :detailsItems="items" data-cy="data-details-items"></details-item>


                    </v-col>
                    
                </v-row>

                 <v-row justify="center">
                    <v-col cols="12" md="6" lg="6" xl="6" class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                        <p class="mb-0 pb-0">Productos</p>
                    </v-col>
                </v-row>

                <v-row justify="center" >
                    <v-col class="py-3" cols="12" md="6" lg="6" xl="6">
                        <table-items data-cy="table-items" :props="tableProps"  :items="tableProps.items">
                            
                        </table-items>
                        
                    </v-col>
                    
                </v-row>

               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" lg="6" xl="6" class="d-flex justify-end">
                    
					<primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from '../../../helpers/Utils';
export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
           
            items: [
               
                
            ],

            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Quotations",
                block:false,
                click: () => {}
            },

            tableProps: {
				headers: [
                     {
						text: 'Producto',
						align: 'left break-words',
						value: 'product_name',
                        width:"50%",
						class: 'table-b-border black--text',
                        sortable: false,
					},
					
                    {
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Costo',
						align: 'center',
						value: 'amount',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Subtotal',
						align: 'center',
						value: 'subtotal',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},

					
				],
				items: [],
				search: '',
				loading: false
			},
           
          
		}
    },
    mounted(){
        this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
    },
	methods: {
         onResize () {
            
            if(window.innerWidth < 960){
                this.cancelBtnData.block = true;
                
            }
            else{
                this.cancelBtnData.block = false;
                
            }
        },
		
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.quotation.get(this.id)
			.then((response) => {
                console.log(response);
                

               
               
                this.items= [
                    { concepto: 'Folio', index: 'prospect', valor:response.data.folio},
                    { concepto: 'Fecha', index: 'description', valor:Utils.globalDateFormat(response.data.date??"")},
                    { concepto: 'Cliente', index: 'project_name', valor:response.data.client.name},
                    { concepto: 'Razón social', index: 'validity', valor:response.data.client.business_name},
                    { concepto: 'R.F.C.', index: 'validity', valor:response.data.client.rfc},
                    { concepto: 'Obra', index: 'foreign_exchange', valor: `${response.data.work.folio} - ${response.data.work.name}` },
                    { concepto: 'Condiciones', index: 'header', valor:response.data.conditions},
                ];

                //products
                this.tableProps.items = response.data.products.map((item) => {
                    return {...item, subtotal: Utils.currencyFormat(parseFloat(item.quantity * item.amount).toFixed(2), ""), amount: Utils.currencyFormat(item.amount, "")};
                });
            
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 